body {
  margin: 0;
  font-family:  'Roboto'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,h2{
  font-family: 'Neo Sans Pro', sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #cccccc; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cccccc; 
}



.scheda-prodotto{
  display:flex
}

.container-prodotto{
  flex:8;
    background: rgb(204,204,204);
    height:100vh;
    overflow-y:scroll;

}

.tabella-prodotto{
  display:flex;
  border:1px solid #666;
}
.colonna-sx{
 flex:3;
 padding:10pt;
 border-right:1px solid #666;

}
.titolo{
  margin-bottom:20pt
}
.descrizione-breve{
  margin-bottom:20pt
}
.immagine{
  max-width:55mm
}

.colonna-dx{
  flex:8;
   padding:10pt;
}
.colonna-dx p {
  margin:0
}
.toaster{
  background-color: #333;
    color: #fff;
    margin: 14px;
    max-width: 100%;
    width: 90%;
    padding: 15px;
    border-radius: 16px;
}
.toaster i{
  position:relative;
  top:-8px;
  left:-5px;

}
.toaster span{
      position: relative;
    top: -8px;
}
.tipologia{
  font-weight:bold;
  margin:15pt 0;
}

.dettagli{
  font-weight:bold;
  margin:10pt 0;
}
.dettagli u{
  display:inline-block;
  min-width:120pt
}


.form-prodotto{
  flex:2;
  max-height:100vh;
  overflow-y:scroll;
  background-color:#eeeeee;
  padding-bottom:300px;
}
.form-prodotto .form-group{
  margin-bottom:0
}




page{
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
  padding:50px;
  font-size:9pt;
  position:relative;
  top:-100px;
  overflow-y:scroll;
  display:flex;
  flex-direction:column;
    justify-content: space-between;
}
page[aria-size="A4"] {  
  width: 21cm;
  height: 29.7cm; 
}
page[aria-size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;  
}


.ql-editor {
    box-sizing:initial; 
    
    height: 100%;
    outline: none;
    overflow-y: auto;
     padding: 5px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    border:2px dotted #ccc
}
.ql-tooltip{
  z-index:9000
}



.zoombar{
   display: flex;
        width: 150px;
        justify-content: start;
        align-items: center;
        position:fixed;
        top:5px;
        z-index:5;
        width:600px


}


input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #666;
  border-radius: 0px;
  border: 0px solid #000000;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #666;
  border: 0px solid #666;
  height: 12px;
  width: 3px;
  border-radius: 1px;
  background: #666;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #666;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #666;
  border-radius: 0px;
  border: 0px solid #000000;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #666;
  border: 0px solid #666;
  height: 23px;
  width: 5px;
  border-radius: 1px;
  background: #666;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #666;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: #666;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 1px #666;
  border: 0px solid #666;
  height: 23px;
  width: 5px;
  border-radius: 1px;
  background: #666;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #666;
}
input[type=range]:focus::-ms-fill-upper {
  background: #666;
}

.scheda-prodotto{
    display:block;
    background:#fff;
    padding:50px 70px;
  }












@media print {
   page {
    margin: 0 auto;
    box-shadow: 0;
    width:100%;
    size:    "A4 portrait";
    zoom: 100%;

  }
  .form-prodotto{
    display:none
  }
  
}